import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const ServicePrincipal = React.lazy(() => import("../components/ServicePrincipal"));
const AboutPrincipal = React.lazy(() => import("../components/AboutPrincipal"));
const BannerPrincipal = React.lazy(() => import("../components/BannerPrincipal"));
const FeaturePrincipal = React.lazy(() => import("../components/FeaturePrincipal"));
const FaqOne = React.lazy(() => import("../components/FaqOne"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarPrincipal = React.lazy(() => import("../components/NavbarPrincipal"));
const PartnerPrincipal = React.lazy(() => import("../components/PartnerPrincipal"));
const FeatureOne = React.lazy(() => import("../components/FeatureOne"));
const FeaturePrincipalTwo = React.lazy(() => import("../components/FeaturePrincipalTwo"));
const ServiceOne = React.lazy(() => import("../components/ServiceOne"));

const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const HomeTwo = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar Two */}
          <NavbarPrincipal />

          {/* Banner Two */}
          <BannerPrincipal />

          {/* Partner Two */}
          <PartnerPrincipal />

          {/* About Two */}
          <AboutPrincipal />

          {/* Counter Two */}
          {/* <CounterPrincipal /> */}

          {/* Productos */}
          <ServiceOne />

          {/* Industria separacion uno */}
          <FeaturePrincipal />

          {/* Solución */}
          <ServicePrincipal />

          {/* Separacion Industria */}
          <FeaturePrincipalTwo />

          {/* Servicios */}
          <FeatureOne />

          {/* Why Choose Us Two */}
          {/* <WhyChooseUsTwo /> */}

          {/* Request Quote One */}
          {/* <RequestQuoteOne /> */}

          {/* Pricing One */}
          {/* <PricingOne /> */}

          {/* Testimonial Two */}
          {/* <TestimonialTwo /> */}

          {/* Video Area One */}
          {/* <VideoAreaOne /> */}

          {/* Preguntas */}
          <div className='faq-area pd-top-120'>
            <FaqOne />
          </div>

          {/* Blog Two */}
          {/* <BlogTwo /> */}

          {/* Footer Two */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeTwo;
